export default {
  translation: {
    'shared.french': 'Français',
    'app.name': 'Espace Partenaire Hellio',
    'auth.hero': 'Bienvenue sur votre\nEspace Partenaire',
  },
  global: {
    password: 'Mot de passe',
    email: 'E-mail',
    logout: 'Déconnexion',
    cancel: 'Annuler',
    required: 'Ce champ est obligatoire',
    internet_connexion_error:
      "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
    'email.required': 'Veuillez renseigner une adresse e-mail valide',
    session_expired: 'Votre session a expiré. Veuillez vous reconnecter.',
    previews: 'Précédents',
    next: 'Suivant',
    yes: 'Oui',
    no: 'Non',
    date: 'Date',
    mr: 'Monsieur',
    mrs: 'Madame',
  },
  login: {
    hero: 'Connexion',
    description: 'Renseignez votre adresse email et votre mot de passe',
    forgot: 'Mot de passe oublié',
    login_error: 'Votre identifiant ou votre mot de passe est incorrect',
    submit: 'Se connecter',
  },
  first_login: {
    hero: 'Première connexion',
    description: 'Renseignez votre mot de passe',
    success: 'Création de votre mot de passe',
    newPassword: 'Nouveau mot de passe*',
    error: "Votre lien à expiré ou n'est plus valide",
    success_description:
      'La création de votre mot de passe a bien été prise en compte.',
    success_action: 'Me connecter',
    submit: 'Valider le mot de passe',
    same: 'Les mots de passes ne sont pas identiques',
    'confirm.password': 'Confirmer mot de passe',
    'confirm.regex.error':
      'Le mot de passe doit contenir 8 caractères : une majuscule, un chiffre et un caractère spécial.',
    'confirm.placeholder.password': 'Confirmation du mot de passe',
  },
  change_password: {
    hero: 'Changement de mot de passe',
    newPassword: 'Nouveau mot de passe*',
    description: 'Renseignez votre nouveau mot de passe',
    submit: 'Valider le mot de passe',
    success: 'Modification du mot de passe',
    success_description:
      'La modification de votre mot de passe a bien été effectuée.',
    success_action: 'Me connecter',
    same: 'Les mots de passes ne sont pas identiques',
    'confirm.password': 'Confirmez le nouveau mot de passe*',
    'confirm.regex.error':
      'Le mot de passe doit contenir 8 caractères : une majuscule, un chiffre et un caractère spécial.',
    'confirm.placeholder.password': 'Confirmation du mot de passe',
  },
  404: {
    hero: 'Page introuvable',
    description: "La page que vous cherchez n'existe pas ou a été déplacée.",
    back: "Retour à l'accueil",
  },
  not_implemented: {
    hero: 'Non implémenté',
  },
  reset_password: {
    hero: 'Mot de passe oublié',
    description:
      'Veuillez saisir votre email de connexion afin de recevoir le lien de réinitialisation de votre mot de passe.',
    submit: 'Valider',
    error: "Votre lien à expiré ou n'est plus valide",
    back: 'Retour à la connexion',
    success: 'Réinitialisation du mot de passe',
    success_description:
      "Nous vous avons envoyé un e-mail pour réinitialiser votre mot de passe. Pour créer votre nouveau mot de passe, il vous suffit de cliquer sur le lien contenu dans l'e-mail et d'en saisir un nouveau.<br>" +
      "<strong>Vous n'avez pas reçu cet e-mail</strong><br> Vérifiez vos courriers indésirables ou contactez : ",
    email: 'service.energie@hellio.com',
  },
  homepage: {
    hero: 'Accueil',
  },
};
